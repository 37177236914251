"use client";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { getLoggedInUser } from "@/lib/user";
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { signOut } from "next-auth/react";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { Fragment, useEffect, useRef, useState } from 'react';
import { FaBars } from "react-icons/fa6";
import ContactPopup from "@/components/common/contact-popup";
import { FaChevronDown } from 'react-icons/fa';

// import NotificationsDropdown from "./notifications-dropdown";
const navigation = [
  { name: 'Home', href: '/', current: false },
  { name: 'About Us', href: '/about-us', current: false },
  { name: 'Our Focus', href: '/our-focus', current: false },
  { name: 'Projects', href: '/projects', current: false },
  // { name: 'Projects', // current: false,
  //   children: [
  //     { name: 'Projects', href: '/projects' },
  //     { name: 'Media', href: '/media' }
  //   ]
  // },
  { name: 'Blogs', href: '/blog', current: false },
  { name: 'Contact Us', href: '/contact-us', current: false },
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export default function Header(props: any) {
  const { session } = props
  // console.log("🚀 ~ Header ~ session:", session)
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const pathname = usePathname();
  const [userDetails, setUserDetails] = useState<any>('')
  const [toggleMenu, setToggleMenu] = useState<any>(false)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = await getLoggedInUser();
        setUserDetails(user?.data)
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setToggleMenu(false);
      }
    };

    const handleScroll = () => {
      setToggleMenu(false);
    };

    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [dropdownRef]);
  
  const handleItemClick = (event: any) => {
    event.stopPropagation();
  };

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Disclosure as="nav" className={`w-full fixed sm:px-6 lg:px-8 z-50 ${isScrolled ? 'top-0 bg-black' : 'lg:top-[20px] top-0 lg:bg-transparent bg-black'} `} onClick={handleItemClick}>
      {({ open }) => (
        <>
          <div className={`mx-auto container px-3 xl:px-8 ${isScrolled ? '' : 'lg:bg-[#0D1E12]/70' } `}>
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 right-0 flex items-center lg:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="relative inline-flex items-center justify-center p-2 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <FaBars className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <FaBars className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-shrink-0 items-center hidden lg:visible lg:block">
                <Link href="/" className="inline-block">
                  <Image
                    height={49} width={200}
                    className="xl:h-[45px] h-[40px] w-auto pt-[4px]"
                    src="https://exalogic-store.s3.us-east-2.amazonaws.com/exa-farms/logo.svg"
                    alt="Logo"
                    blurDataURL="URL"
                    placeholder="blur"
                  />
                </Link>
              </div>
              <div className="flex items-center">
                <div className="visible lg:hidden flex flex-shrink-0 items-center">
                  <Link href="/" className="inline-block">
                    <Image
                      height={49} width={200}
                      className="h-[40px] w-auto pt-[4px] pl-[8px]"
                      src="https://exalogic-store.s3.us-east-2.amazonaws.com/exa-farms/logo.svg"
                      alt="Logo"
                      blurDataURL="URL"
                      placeholder="blur"
                    />
                  </Link>
                </div>
                <div className="hidden lg:block text-white">
                  <div className="flex md:space-x-4 items-center">
                    {navigation.map((item:any, index:number) => (
                      <div key={index} className="relative">
                        {item?.children ? (
                          <>
                            <button
                              className={classNames(
                                'text-[16px] font-normal flex items-center',
                                (pathname === item.href || item.children.some((child:any) => pathname === child.href)) ? 'text-[#C98A43] font-bold mt-[3px]' : 'text-white hover:text-[#C98A43] mt-[3px]'
                              )}
                              aria-current={pathname === item.href ? 'page' : undefined}

                              onClick={() => setToggleMenu(!toggleMenu)}
                            >
                              {item.name}
                              <FaChevronDown className="ml-1 text-[12px]" />
                            </button>
                            {toggleMenu ? <div ref={dropdownRef} className="absolute mt-2 w-30 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden">
                              {item?.children?.map((child:any, index:number) => (
                                <Link
                                  key={index}
                                  href={child.href}

                                  onClick={() => setToggleMenu(false)}
                                  className="block px-4 py-2 text-sm text-[#bbbbbb] hover:bg-gray-100  hover:text-[#C98A43]"
                                >
                                  {child.name}
                                </Link>
                              ))}
                            </div> : null}
                          </>
                        ) : (
                          <Link
                            key={item.name}
                            href={item.href}
                            onClick={() => setToggleMenu(false)}
                            className={classNames(
                              'px-3 py-2 text-[16px]',
                              pathname === item.href ? 'text-[#C98A43] font-bold' : 'text-white font-normal hover:text-[#C98A43]'
                            )}
                            aria-current={pathname === item.href ? 'page' : undefined}
                          >
                            {item.name}
                          </Link>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div>
              <ContactPopup/>
              {/*<Link*/}
                  {/*href='/contact-us'*/}
                  {/*className='hover:bg-[#C98A43]/90 bg-[#C98A43] font-normal text-white uppercase px-3 py-2 text-[14px] lg:inline-block  hidden tracking-[2px]'>*/}
                  {/*<ContactPopup/>*/}
              {/*</Link>*/}
              </div>
              {(session != undefined || session != null) &&
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-0 sm:pr-0">
                  {/* <NotificationsDropdown /> */}
                  <div className="flex items-center sm:hidden">
                    {/* Mobile menu button*/}
                    <Disclosure.Button className="relative inline-flex items-center justify-center p-2 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                      <span className="absolute -inset-0.5" />
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <FaBars className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <FaBars className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button className="overflow-hidden relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">Open user menu</span>
                        {/* <img
                        className="h-8 w-8 rounded-full"
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      /> */}
                        <Avatar>
                          {userDetails?.profileImag ? <AvatarImage src={userDetails?.profileImag ? userDetails?.profileImag : session?.user?.user?.image} alt={session?.user?.user?.name} />
                            :
                            <AvatarFallback className="AvatarFallback" delayMs={600}>
                              {/* {session?.user?.user?.name?.substring(0, 1)} */}
                              {userDetails?.firstName?.substring(0, 1)}
                            </AvatarFallback>}
                        </Avatar>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="overflow-hidden absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              href="/user-account/profile"
                              className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                            >
                              My Profile
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              href="/user-account/settings"
                              className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                            >
                              Settings
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <p
                              // href="/login"
                              onClick={() => signOut(
                                { callbackUrl: '/' }
                              )}
                              className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                            >
                              Sign out
                            </p>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>}
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden mobile-nav">
            <div className="space-y-0 px-0 pt-2">
              {navigation.map((item:any, index) => (
                <Fragment key={index}>
                  {item.children ? (
                    <>
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className={classNames(
                          (pathname === item.href || item.children.some((child:any) => pathname === child.href)) ? 'bg-gray-900 text-white' : 'text-[#bbbbbb] hover:bg-gray-700 hover:text-white',
                          'block px-6 py-3 text-base font-medium mt-0'
                        )}
                        aria-current={pathname === item.href ? 'page' : undefined}

                        onClick={(e) => {
                          e.preventDefault(); // Prevent the default behavior
                          setToggleMenu(!toggleMenu);
                        }}
                      >
                        {item.name}

                      </Disclosure.Button>
                      {toggleMenu ? <div className="absolute w-full origin-top-right rounded-none bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden">
                        {item.children.map((child:any, index:number) => (
                          <Disclosure.Button
                          key={index}
                          as="a"
                          href={child.href}
                          className={classNames(
                            pathname === child.href ? 'bg-gray-900 text-white' : 'text-[#bbbbbb] hover:bg-gray-700 hover:text-white',
                            'block px-6 py-3 text-base font-small mt-0'
                          )}
                          aria-current={pathname === child.href ? 'page' : undefined}
                        >
                          {child.name}
                        </Disclosure.Button>
                        ))}
                      </div> : null}
                      <div className="border border-color-[#ddd] my-0 mx-6 w-auto"></div>
                    </>
                  ) : (
                    <div key={index}>
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className={classNames(
                          pathname === item.href ? 'bg-gray-900 text-white' : 'text-[#bbbbbb] hover:bg-gray-700 hover:text-white',
                          'block px-6 py-3 text-base font-medium mt-0'
                        )}
                        aria-current={pathname === item.href ? 'page' : undefined}
                      >
                        {item.name}
                      </Disclosure.Button>
                      <div className="border border-color-[#ddd] my-0 mx-6 w-auto"></div>
                    </div>
                  )}
                </Fragment>
              ))}
              <ContactPopup/>
              {/*<Link*/}
                {/*href='/login'*/}
                {/*className='text-[#bbbbbb] hover:bg-gray-700 hover:text-white block px-6 py-3 text-base font-medium mt-0'>*/}
                {/*Enquire*/}
              {/*</Link>*/}
              {/* {session == undefined && <Link
                href='/login'
                className='text-gray-700 hover:bg-gray-700 hover:text-white
                      block px-6 py-3 text-base font-medium mt-0'>
                Sign In
              </Link>} */}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
