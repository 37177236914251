import {PaginationLink} from "../ui/pagination";

"use client"

import { Input } from '../ui/input';
import { Button } from '../ui/button';
import Link from 'next/link';
import Image from 'next/image';
import { LuMapPin } from "react-icons/lu";
import { MdOutlineLocalPhone, MdMailOutline, MdOutlineWhatsapp } from "react-icons/md";



const Footer = (props: any) => {

    return (
        <footer className='bg-[url(https://exalogic-store.s3.us-east-2.amazonaws.com/exa-farms/footer-bg.webp)] py-10 bg-no-repeat bg-cover'>
            <div className='container'>
                {/* <div className='bg-[#C98A43] sm:p-10 p-6'>
                    <div className='grid grid-cols-12 gap-2'>
                        <div className='col-span-12 lg:col-span-5'>
                            <h4 className='text-[25px] md:text-[34px] text-white font-semibold'>Stay connected</h4>
                        </div>
                        <div className='col-span-12 lg:col-span-7 block sm:flex gap-1 items-center'>
                            <Input className='border-2 border-white bg-transparent rounded-none flex-grow placeholder:text-white h-[50px] mb-2 sm:mb-0 text-white' placeholder='Your mail address'/>
                            <Button className='bg-white text-[#C98A43] rounded-none px-10 h-[50px] sm:w-auto w-full md:h-full' disabled>SUBSCRIBE NOW</Button>
                        </div>
                    </div>
                </div> */}
                <div className='bg-[#242424] sm:p-10 p-6 text-white'>
                    <div className='grid grid-cols-12 gap-3'>
                        <div className='col-span-12 md:col-span-4 mb-3 md:mb-0 order-2 md:order-1'>
                            <p className='text-[30px] font-semibold'>Address</p>
                            <div className='border-b-2 border-[#C98A43] w-32 my-3'></div>
                            <p className='text-sm flex items-center mb-1'><LuMapPin className="me-2"/> Sulikunte village, Sasalu Hobli, <br/>Doddaballapura, Bengaluru- 561204.</p>
                            <p className='text-sm hover:text-[#c98a43] mb-1'>
                                <Link href="mailto:visitharmonyestate@gmail.com" target="_blank" rel="noopener" className='block flex items-center'>
                                    <MdMailOutline className="me-2"/> visitharmonyestate@gmail.com
                                </Link>
                            </p>
                            <p className='text-sm hover:text-[#c98a43] mb-1'>
                                <Link href="tel:+916364306015" target="_blank" rel="noopener" className='block flex items-center'>
                                    <MdOutlineLocalPhone className="me-2"/> +91 6364306015
                                </Link>
                            </p>
                            <p className='text-sm hover:text-[#c98a43]'>
                                <Link href="https://wa.me/+916364306015" target="_blank" rel="noopener" className='block flex items-center'>
                                    <MdOutlineWhatsapp className="me-2"/> +91 6364306015
                                </Link>
                            </p>
                        </div>
                        <div className='col-span-12 md:col-span-4 mb-3 md:mb-0 order-last md:order-3'>
                            <p className='text-lg font-semibold mb-3 md:mb-6'>Quick Links</p>
                            <ul className='text-sm space-y-3'>
                                <li>
                                    <Link href='/' className="hover:text-[#c98a43]">Home</Link>
                                </li>
                                <li>
                                    <Link href='/about-us' className="hover:text-[#c98a43]">About Us</Link>
                                </li>
                                <li>
                                    <Link href='/our-focus' className="hover:text-[#c98a43]">Our Focus</Link>
                                </li>
                                <li>
                                    <Link href='/projects' className="hover:text-[#c98a43]">Projects</Link>
                                </li>
                                <li>
                                    <Link href='/blog' className="hover:text-[#c98a43]">Blog</Link>
                                </li>
                            </ul>
                        </div>
                        <div className='col-span-12 md:col-span-4 mb-3 md:mb-0 order-first md:order-4'>
                            <Image src='https://exalogic-store.s3.us-east-2.amazonaws.com/exa-farms/logo.svg' alt='Logo' width='100' height='100' className='w-full h-auto max-w-44'/>
                            <div className="mt-3 md:mt-5 lg:mt-10">
                                <p className="text-[13px] font-semibold mt-4 mb-2">FOLLOW US</p>
                                <div className="flex space-x-3">
                                    <Link href="https://wa.me/+919356381248" target="_blank" rel="noopener"><Image src='https://exalogic-store.s3.us-east-2.amazonaws.com/exa-farms/whatsapp.png' alt='whats-up' width='25' height='25'/></Link>
                                    <Link href="https://www.facebook.com/visitharmony" target="_blank" rel="noopener"><Image src='https://exalogic-store.s3.us-east-2.amazonaws.com/exa-farms/facebook.png' alt='Facebook' width='25' height='25'/></Link>
                                    <Link href="https://www.instagram.com/visitharmonyestate" target="_blank" rel="noopener"><Image src='https://exalogic-store.s3.us-east-2.amazonaws.com/exa-farms/instagram.png' alt='instagram' width='25' height='25'/></Link>
                                    <Link href="https://x.com/i/flow/login?redirect_after_login=%2FHarmonyEstate24" target="_blank" rel="noopener"><Image src='https://exalogic-store.s3.us-east-2.amazonaws.com/exa-farms/twitter.png' alt='twitter' width='25' height='25'/></Link>
                                    <Link href="https://www.linkedin.com/company/visitharmony/" target="_blank" rel="noopener"><Image src='https://exalogic-store.s3.us-east-2.amazonaws.com/exa-farms/linkedin.png' alt='linkedin' width='25' height='25'/></Link>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;