import React, { useState } from 'react';
import { Button } from "../ui/button";
import ContactForm from "../contactUs/contact-form";
import { IoCloseSharp } from "react-icons/io5";

const ContactPopup = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    return (
        <div>
            <Button className="bg-[#C98A43] hover:bg-[#FFFFFF] hover:text-[#000000] cursor-pointer rounded-none inline-block w-[90px] sm:w-[148px] h-[30px] sm:h-[45px] text-[10px] sm:text-[14px] uppercase font-normal p-0 me-11 lg:m-0" onClick={togglePopup}>Enquire Now</Button>

            {isPopupOpen && (
                <div className="enquire-overlay top-[0] left-[0] w-full h-full flex items-center justify-center z-[1000] fixed" onClick={togglePopup}>
                    <div className="enquire-container relative bg-[#475035] px-10 pb-10 w-full max-w-[300px]" onClick={(e) => e.stopPropagation()}>
                        <h3 className="text-[#FFFFFF] font-semibold mb-5 pt-5">Enquire Now</h3>
                        <ContactForm/>
                        <Button className="enquire-button bg-[#C98A43] absolute top-[0] right-[0] rounded-none" onClick={togglePopup}><IoCloseSharp/></Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ContactPopup;
